<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import TokenImage from "./TokenImage.vue";
const tokens = useTokensStore();
</script>
<template>
  <div
    v-if="
      tokens.blockchain && tokens.newBlockchain && tokens.newBlockchain.tokens
    "
  >
    <div>
      <h2>Blockchain tokens</h2>
    </div>
    <div class="d-flex flex-wrap justify-center">
      <TokenImage
        v-for="(token, tokenI) in tokens.newBlockchain.tokens"
        :key="tokenI"
        :imageId="token.image"
        :token="token"
        :title="token.full_name"
        :class="`${
          tokens.changes
            .filter((c) => c.type === 'added')
            .map((t) => t.token.id)
            .includes(token.id)
            ? 'bg-lightgreen'
            : ''
        }`"
      />
    </div>
  </div>
</template>
