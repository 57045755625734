<script setup>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "@/plugins/firebase.js";
import { onMounted, ref, watch } from "vue";
const props = defineProps(["imageId"]);
const downloadedImage = ref(null);
const getUniqueImage = async function (fileId) {
  downloadedImage.value = await getDownloadURL(
    storageRef(storage, `app/tokens-images/${fileId}`)
  );
};

onMounted(() => {
  getUniqueImage(props.imageId);
});

watch(
  () => props.imageId,
  (fileId) => {
    getUniqueImage(fileId);
  }
);
</script>

<template>
  <div v-if="downloadedImage">
    <img :src="downloadedImage" class="avatar" />
  </div>
</template>
