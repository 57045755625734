<template>
  <div class="queued-scrap">
    <div class="queued-scrap__left">
      <div class="queued-scrap__head">
        <div class="queued-scrap__title">{{ chain }}:{{ address }}</div>
      </div>
      <div class="queued-scrap__detail">
        <div class="queued-scrap__id">{{ id ? id : "No specific id" }}</div>
      </div>
      <div class="queued-scrap__added">
        Added: {{ dtAdded ? $format.fullDate(dtAdded) : "Unknown" }}
      </div>
    </div>
    <div class="queued-scrap__right"></div>
  </div>
</template>

<script>
export default {
  name: "QueuedScrap",
  props: {
    chain: String,
    address: String,
    id: String,
    dtAdded: Date,
  },
};
</script>

<style lang="scss" scoped>
.queued-scrap {
  box-shadow: 0 2px 3px rgba(black, 0.3);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(black, 0.02);
}

.queued-scrap__left {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  white-space: nowrap;
}

.queued-scrap__right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.queued-scrap__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  max-width: 100%;
}

.queued-scrap__title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 5px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.queued-scrap__label {
  flex-shrink: 0;
}

.queued-scrap__detail {
  font-size: 0.8rem;
  font-weight: 300;
  color: grey;
  fill: grey;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  max-width: 100%;

  svg {
    height: 14px;
    margin-right: 4px;
    flex-shrink: 0;
  }

  .queued-scrap__id {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.queued-scrap__added {
  font-style: italic;
  font-size: 0.9rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.queued-scrap__button {
  margin-left: 6px;
}

@media (max-width: 600px) {
  .queued-scrap__title {
    font-size: 1.1rem;
  }
  .queued-scrap__button {
    min-width: 0;
    width: 36px;

    .mdc-button__icon {
      margin: 0;
    }

    span {
      display: none;
    }
  }
}
</style>
