<template>
  <Dialog ref="dialog" :defaultWidth="500">
    <div class="mdc-dialog__content twitter-search-form">
      <h2>Search Twitter Handle</h2>
      <TextField
        v-model="searchedTwitter"
        hintText="Twitter Handle"
        class="mdc-text-field--filled twitter-search-form__field"
        ripple
        ref="input"
      />
      <div
        class="twitter-search-form__warning"
        v-if="!twitterIsValid && searchedTwitter.length > 0"
      >
        This should be only alphanumeric characters and underscores.
      </div>
      <Button
        :disabled="!twitterIsValid"
        v-if="!searched"
        outlined
        @click="handleSearch"
        class="twitter-search-form__button"
      >
        Search
      </Button>
      <div v-else>
        <div v-if="foundAccountId">An account is linked to that Twitter!</div>
        <div v-else>No account is linked to that Twitter yet.</div>
        <Button
          outlined
          v-if="foundAccountId"
          class="twitter-search-form__button"
          @click="openWhitelistAccount"
        >
          Whitelist Account
        </Button>
      </div>
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard"> Close </Button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/atoms/material/Dialog.vue";
import Button from "@/components/atoms/material/Button.vue";
import TextField from "@/components/atoms/material/TextField.vue";

export default {
  name: "TwitterSearchForm",
  components: {
    Dialog,
    Button,
    TextField,
  },
  data: () => {
    return {
      searchedTwitter: "",
      foundAccountId: null,
      searched: false,
    };
  },
  watch: {
    searchedTwitter() {
      this.searched = false;
      this.foundAccountId = null;
    },
  },
  computed: {
    twitterIsValid() {
      const regex = new RegExp("^[A-Za-z0-9_]+$");
      return regex.test(this.searchedTwitter);
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open();
      this.$refs.input.focus();
    },
    close() {
      this.$refs.dialog.close();
    },
    async handleSearch() {
      try {
        const response = await fetch(
          `https://europe-west1-cryptos-tools.cloudfunctions.net/get-account-from-handle?handle=${this.searchedTwitter}`
        );
        const data = await response.json();
        this.searched = true;
        if (data.data.account) {
          this.foundAccountId = data.data.account.uid;
        }
      } catch (error) {
        alert(
          "Failed to search the handle. This is possibly a wrong Twitter handle. Check your internet connection and try again."
        );
        throw error;
      }
    },
    openWhitelistAccount() {
      this.searchedAddress = "";
      this.close();
      this.$parent.openForm(this.foundAccountId, "account", {
        createNew: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.twitter-search-form {
  text-align: center;
}

.twitter-search-form__field {
  width: 100%;
}

.twitter-search-form__warning {
  font-size: 0.8em;
  font-weight: 500;
  color: orange;
}

.twitter-search-form__button {
  margin: 10px 4px 0 4px;
}
</style>
