<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import { nextTick, watch, ref } from "vue";
import CenteredDialog from "./CenteredDialog.vue";
import ImageUploader from "./ImageUploader.vue";
import TokenImage from "./TokenImage.vue";

const tokens = useTokensStore();

const editATokenDialog = ref(null);

watch(
  () => tokens.editingAToken,
  (editingAToken) => {
    if (editingAToken) {
      nextTick(() => {
        editATokenDialog.value.focus();
        document.querySelector("body").style.overflowY = "clip";
      });
    }
    if (!editingAToken) {
      nextTick(() => {
        document.querySelector("body").style.overflowY = "auto";
      });
    }
  }
);

// TODO watch search_full_name and update the newEditedToken accordingly
watch(
  () => tokens.search_full_name,
  (search_full_name) => {
    if (search_full_name) {
      var result = tokens?.newBlockchain?.tokens.find(
        (token) => token.full_name === search_full_name
      );
      if (result) {
        tokens.newEditedToken = {
          ...result,
          place_after_full_name: result.full_name,
        };
      } else {
        // tokens.resetEditedToken();
      }
    }
  }
);
</script>
<template>
  <div v-show="tokens.editingAToken">
    <CenteredDialog>
      <div
        ref="editATokenDialog"
        tabindex="-1"
        @keyup.esc="tokens.editingAToken = false"
        id="editATokenDialog"
      >
        <form @submit.prevent="">
          <div>
            <h2>Edit a Token</h2>
            <div v-if="tokens.blockchain && tokens.blockchain.tokens">
              <label for="search_full_name">search_full_name</label>
              <input
                autocomplete="do-not-autofill"
                required
                v-model="tokens.search_full_name"
                type="text"
                name="search_full_name"
                id="search_full_name"
                list="searchTokenSuggestions"
              />
              <datalist id="searchTokenSuggestions">
                <option
                  :value="tokenSuggestion.full_name"
                  v-for="(tokenSuggestion, tokenSuggestionI) in tokens
                    .newBlockchain.tokens"
                  :key="tokenSuggestionI"
                ></option>
              </datalist>
            </div>
            <div v-show="tokens.search_full_name_is_valid">
              <div v-if="tokens.blockchain && tokens.blockchain.tokens">
                <label for="place_after_full_name"
                  >place_after_full_name*</label
                >
                <input
                  autocomplete="do-not-autofill"
                  required
                  :pattern="
                    tokens.editedToken_place_after_full_name_valid
                      ? tokens.newEditedToken.place_after_full_name
                      : 'invalidfullname'
                  "
                  v-model="tokens.newEditedToken.place_after_full_name"
                  type="text"
                  name="place_after_full_name"
                  id="place_after_full_name"
                  list="tokenSuggestions"
                />
                <datalist id="tokenSuggestions">
                  <option
                    :value="tokenSuggestion.full_name"
                    v-for="(tokenSuggestion, tokenSuggestionI) in tokens
                      .newBlockchain.tokens"
                    :key="tokenSuggestionI"
                  ></option>
                </datalist>
              </div>
              <div>
                position:
                {{ tokens.editedToken_place_after_position }}
              </div>
              <div>
                <label for="address">address*</label>
                <!--
                Example of valid input
                0x1234567890123456789012345678901234567890
               -->
                <input
                  autocomplete="do-not-autofill"
                  required
                  title="40 hexadecimal characters that begins with '0x' or 32-44 alphanumeric characters"
                  pattern="^(0x[a-fA-F0-9]{40})|([1-9A-HJ-NP-Za-km-z]{32,44})$"
                  v-model="tokens.newEditedToken.address"
                  type="text"
                  name="address"
                  id="address"
                />
              </div>
              <div>
                <label for="full_name">full_name*</label>
                <input
                  autocomplete="do-not-autofill"
                  required
                  :pattern="
                    tokens.newEditedToken.full_name
                      ? tokens.newEditedToken.full_name
                      : 'invalidfullname'
                  "
                  v-model="tokens.newEditedToken.full_name"
                  type="text"
                  name="full_name"
                  id="full_name"
                />
              </div>
              <div class="checkboxInputWrapper">
                <label for="is_soon">is_soon</label>
                <input
                  v-model="tokens.newEditedToken.is_soon"
                  type="checkbox"
                  name="is_soon"
                  id="is_soon"
                />
              </div>
              <div class="checkboxInputWrapper">
                <label for="is_moonscan">is_moonscan</label>
                <input
                  v-model="tokens.newEditedToken.is_moonscan"
                  type="checkbox"
                  name="is_moonscan"
                  id="is_moonscan"
                />
              </div>
              <div class="checkboxInputWrapper">
                <label for="is_rugged">is_rugged</label>
                <input
                  v-model="tokens.newEditedToken.is_rugged"
                  type="checkbox"
                  name="is_rugged"
                  id="is_rugged"
                />
              </div>
              <div v-if="tokens.newEditedToken && tokens.newEditedToken.image">
                <TokenImage :imageId="tokens.newEditedToken.image" />
              </div>
              <div v-if="tokens.editingAToken">
                <ImageUploader />
              </div>
              <div>
                <label for="website">website*</label>
                <input
                  required
                  pattern="^https:\/\/\S+\.\S+$"
                  v-model="tokens.newEditedToken.website"
                  type="url"
                  name="website"
                  id="website"
                />
              </div>
              <div>
                <label for="website">youtube</label>
                <input
                  pattern="^https:\/\/\S+\.\S+$"
                  v-model="tokens.newEditedToken.youtube"
                  type="url"
                  name="website"
                  id="website"
                />
              </div>
            </div>
            <div class="dialog-actions-wrapper">
              <div>
                <button
                  class="elevated"
                  @click="
                    tokens.resetEditedToken();
                    tokens.editingAToken = false;
                  "
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  class="elevated primary"
                  :disabled="
                    (!tokens.previewUrl && !tokens.newEditedToken.image) ||
                    !tokens.newEditedToken.full_name ||
                    !tokens.newEditedToken.address ||
                    !tokens.newEditedToken.website ||
                    !tokens.newEditedToken.place_after_full_name ||
                    !tokens.editedToken_place_after_full_name_valid ||
                    !tokens.newEditedToken.website.match(
                      /^https:\/\/\S+\.\S+$/
                    ) ||
                    (tokens.newEditedToken.youtube?.length > 0 &&
                      !tokens.newEditedToken.youtube.match(
                        /^https:\/\/\S+\.\S+$/
                      ))
                  "
                  type="submit"
                  value="Submit"
                  @click="tokens.addEditedTokenToChanges()"
                >
                  <div>Save edited token</div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CenteredDialog>
  </div>
</template>
