<template>
  <div
    :class="'mdc-dialog' + (defaultWidth ? ' mdc-dialog--custom-width' : '')"
  >
    <div class="mdc-dialog__container">
      <div
        class="mdc-dialog__surface"
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="my-dialog-title"
        aria-describedby="my-dialog-content"
        :style="defaultWidth ? `width:${defaultWidth}px;` : ''"
      >
        <slot></slot>
      </div>
    </div>
    <div class="mdc-dialog__scrim"></div>
  </div>
</template>

<script>
import { MDCDialog } from "@material/dialog";

export default {
  name: "MaterialDialog",
  props: {
    defaultWidth: Number,
  },
  data() {
    return {
      mdcComponent: undefined,
    };
  },
  mounted: function () {
    this.mdcComponent = new MDCDialog(this.$el);
    this.mdcComponent.listen("MDCDialog:closing", () => {
      this.$emit("closing");
    });
    this.mdcComponent.listen("MDCDialog:opened", () => {
      this.$emit("opened");
    });
  },
  unmounted: function () {
    this.mdcComponent.destroy();
  },
  methods: {
    open() {
      this.mdcComponent.open();
    },
    close() {
      this.mdcComponent.close();
    },
  },
};
</script>

<style lang="scss">
@use "@material/dialog";

.mdc-dialog__actions {
  min-height: initial;
}

.mdc-dialog--custom-width {
  // When using custom width we just set max-width really high so that it
  // doesn't interfere.
  @include dialog.max-width(3000px, 16px);
}
</style>
