<template>
  <!-- Dialogs -->
  <WhitelistedElementForm
    ref="whitelistedElementForm"
    class="--temp-prevent-broken-style"
  />
  <AddressSearchForm
    ref="addressSearchForm"
    class="--temp-prevent-broken-style"
  />
  <TwitterSearchForm
    ref="twitterSearchForm"
    class="--temp-prevent-broken-style"
  />
  <!-- Page Content -->
  <main class="pageContent --temp-prevent-broken-style">
    <div>
      <h1 class="whitelist__title">Whitelist</h1>
      <div class="whitelist__buttons">
        <Button
          outlined
          class="whitelist__button"
          @click="$refs.addressSearchForm.open()"
        >
          <i class="material-icons mdc-button__icon" aria-hidden="true">
            add_circle </i
          ><span>Address</span></Button
        >
        <Button
          outlined
          class="whitelist__button"
          @click="$refs.twitterSearchForm.open()"
        >
          <i class="material-icons mdc-button__icon" aria-hidden="true">
            add_circle </i
          ><span>Twitter</span></Button
        >
      </div>
      <Loader
        v-if="
          this.whitelistStore.whitelistedAccounts.length == 0 ||
          this.whitelistStore.whitelistedAddresses.length == 0
        "
        :size="60"
        class="whitelist__loader"
      />
      <WhitelistedElement
        v-else
        v-for="el in full_list"
        :key="el.id"
        :displayName="el.note"
        :id="el.id"
        :dtAdded="el.dt_added ? el.dt_added.toDate() : null"
        :endDate="el.end_date ? el.end_date.toDate() : null"
        :expired="
          el.end_date ? (el.end_date >= timestampNow() ? false : true) : false
        "
        :type="el.type"
        class="whitelist__element"
      />
    </div>
  </main>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";
import WhitelistedElement from "@/components/molecules/WhitelistedElement.vue";
import WhitelistedElementForm from "@/components/molecules/WhitelistedElementForm.vue";
import AddressSearchForm from "@/components/molecules/AddressSearchForm.vue";
import TwitterSearchForm from "@/components/molecules/TwitterSearchForm.vue";
import Loader from "@/components/atoms/Loader.vue";
import { Timestamp } from "firebase/firestore/lite";
import { mapStores } from "pinia";
import { useWhitelistStore } from "@/stores/whitelist.js";

export default {
  name: "WhitelistView",
  components: {
    WhitelistedElement,
    WhitelistedElementForm,
    AddressSearchForm,
    TwitterSearchForm,
    Loader,
    Button,
  },
  computed: {
    ...mapStores(useWhitelistStore),
    full_list() {
      return this.whitelistStore.whitelistedAccounts
        .map((el) => {
          return { type: "account", ...el };
        })
        .concat(
          this.whitelistStore.whitelistedAddresses.map((el) => {
            return { type: "address", ...el };
          })
        )
        .sort((a, b) => {
          if (!("dt_added" in a) && !("dt_added" in b)) {
            return 0;
          } else if (!("dt_added" in a)) {
            return 1;
          } else if (!("dt_added" in b)) {
            return -1;
          } else {
            return b.dt_added - a.dt_added;
          }
        });
    },
  },
  methods: {
    openForm(...args) {
      this.$refs.whitelistedElementForm.open(...args);
    },
    timestampNow() {
      return Timestamp.now();
    },
  },
  mounted: function () {
    this.whitelistStore.getWhitelist();
  },
};
</script>

<style lang="scss" scoped>
.whitelist__title {
  text-align: center;
}

.whitelist__buttons {
  text-align: center;
  margin-bottom: 20px;
}

.whitelist__button {
  margin: 0 4px 10px 4px;
}

.whitelist__loader {
  margin: auto;
}

.whitelist__element {
  margin: 0 10px 10px 10px;
}
</style>
