<script setup>
import { useTokensStore } from "@/stores/tokens.js";

import ListItemToken from "./ListItemToken.vue";

const tokens = useTokensStore();
</script>

<template>
  <div
    class="blockchain-homepage-lists-wrapper"
    v-if="tokens.selectedBlockchainId && tokens.blockchain"
  >
    <div id="featuredWrapper" class="blockchain-homepage-list">
      <div>
        <h2>Featured</h2>
      </div>

      <ListItemToken
        v-for="(featuredTokenId, featuredTokenIdI) in tokens.newBlockchain
          .featured"
        :key="featuredTokenIdI"
        :tokenId="featuredTokenId"
        :tokenPosition="featuredTokenIdI"
        :tokenList="'featured'"
      />
    </div>
    <div id="mostVisitedWrapper" class="blockchain-homepage-list">
      <div>
        <h2>Most visited</h2>
      </div>
      <ListItemToken
        v-for="(mostVisitedTokenId, mostVisitedTokenIdI) in tokens.newBlockchain
          .most_visited"
        :key="mostVisitedTokenIdI"
        :tokenId="mostVisitedTokenId"
        :tokenPosition="mostVisitedTokenIdI"
        :tokenList="'most_visited'"
      />
    </div>
    <div id="recentlyListedWrapper" class="blockchain-homepage-list">
      <div>
        <h2>Recently listed</h2>
      </div>
      <ListItemToken
        v-for="(recentlyListedTokenId, recentlyListedTokenIdI) in tokens
          .newBlockchain.recently_listed"
        :key="recentlyListedTokenIdI"
        :tokenId="recentlyListedTokenId"
        :tokenPosition="recentlyListedTokenIdI"
        :tokenList="'recently_listed'"
      />
    </div>
  </div>
</template>
