import { defineStore } from "pinia";
import { db } from "@/plugins/firebase.js";
import {
  getDocs,
  collection,
  addDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore/lite";

export const useLabelsStore = defineStore("labels", {
  state: () => {
    return {
      queuedScraps: [],
      labelsScraps: [],
      queuedScraps_loading: true,
      labelsScraps_loading: true,
    };
  },

  actions: {
    async getQueuedScraps() {
      const query = await getDocs(collection(db, "arkham_scrap_todo"));
      this.queuedScraps = query.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      this.queuedScraps_loading = false;
    },
    async getFreshLabelsScraps() {
      const now = new Date();
      const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);
      const querySnapshot = await getDocs(
        query(
          collection(db, "arkham_labels"),
          where("dt_update", ">=", twentyFourHoursAgo)
        )
      );
      this.labelsScraps = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      this.labelsScraps_loading = false;
    },
    async queueScrap(authorId, newdata) {
      const nowTimestamp = Timestamp.now();
      await addDoc(collection(db, "arkham_scrap_todo"), {
        ...newdata,
        dt_added: nowTimestamp,
        author_id: authorId,
      });
      this["queuedScraps"].push({ dt_added: nowTimestamp, ...newdata });
    },
  },
});

export default useLabelsStore;
