<template>
  <svg
    v-if="type == 'account'"
    viewBox="0 0 444 263"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    style="padding: 1px 0; box-sizing: border-box"
  >
    <path
      d="M5.269075 256.031s-16.9674825-58.5106425 9.9631125-85.4412375c20.8319625-20.8319625 54.65220075-20.82592425 75.478125 0 20.82592425 20.82592425 20.8319625 54.6461625 0 75.478125C63.7797175 272.9984825 5.269075 256.031 5.269075 256.031ZM88.7442526 85.1003592C69.7134846 52.1381072 101.23610496.00527536 101.23610496.00527536s60.9173324-1.237408 79.9481004 31.724844l86.7891536 150.3232004c14.7377808 25.5265812 5.97477352 58.18221272-19.5364764 72.911142-25.51124992 14.72892928-58.16569556 5.98567904-72.90347636-19.54090216L88.7442526 85.1003592ZM257.5642526 85.1003592C238.5334846 52.1381072 270.05610496.00527536 270.05610496.00527536s60.9173324-1.237408 79.9481004 31.724844l86.7891536 150.3232004c14.7377808 25.5265812 5.97477352 58.18221272-19.5364764 72.911142-25.51124992 14.72892928-58.16569556 5.98567904-72.90347636-19.54090216L257.5642526 85.1003592Z"
    />
  </svg>
  <svg v-else viewBox="0 0 458.531 458.531" xml:space="preserve">
    <path
      d="M336.688 343.962c-21.972-.001-39.848-17.876-39.848-39.848v-66.176c0-21.972 17.876-39.847 39.848-39.847h103.83c.629 0 1.254.019 1.876.047v-65.922c0-16.969-13.756-30.725-30.725-30.725H30.726C13.756 101.49 0 115.246 0 132.215v277.621c0 16.969 13.756 30.726 30.726 30.726h380.943c16.969 0 30.725-13.756 30.725-30.726v-65.922c-.622.029-1.247.048-1.876.048h-103.83z"
    />
    <path
      d="M440.518 219.925h-103.83c-9.948 0-18.013 8.065-18.013 18.013v66.176c0 9.948 8.065 18.013 18.013 18.013h103.83c9.948 0 18.013-8.064 18.013-18.013v-66.176c0-9.949-8.065-18.013-18.013-18.013zm-68.052 77.099c-14.359 0-25.999-11.64-25.999-25.999s11.64-25.999 25.999-25.999c14.359 0 25.999 11.64 25.999 25.999 0 14.359-11.64 25.999-25.999 25.999zM358.169 45.209c-6.874-20.806-29.313-32.1-50.118-25.226L151.958 71.552h214.914l-8.703-26.343z"
    />
  </svg>
</template>

<script>
export default {
  name: "WhitelistedTypeLogo",
  props: {
    type: String,
  },
};
</script>
