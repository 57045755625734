<template>
  <div class="label-scrap">
    <div class="label-scrap__left">
      <div class="label-scrap__head">
        <div class="label-scrap__title" v-if="final_label">
          {{ final_label }}
        </div>
        <div class="label-scrap__title --grey" v-else>No label found</div>
      </div>
      <div class="label-scrap__detail">
        <div class="label-scrap__address">{{ address }}</div>
      </div>
      <div class="label-scrap__detail" v-if="etherscan_name">
        <div class="label-scrap__address">Etherscan: {{ etherscan_name }}</div>
      </div>
      <div class="label-scrap__added">
        Added: {{ dtUpdate ? $format.fullDate(dtUpdate) : "Unknown" }}
      </div>
    </div>
    <div class="label-scrap__right"></div>
  </div>
</template>

<script>
export default {
  name: "LabelScrap",
  props: {
    label: String,
    address: String,
    dtUpdate: Date,
    etherscan_name: String,
    entity: String,
  },
  computed: {
    final_label() {
      if (this.entity !== null && this.label !== null) {
        return `${this.entity}: ${this.label}`;
      } else if (this.entity !== null) {
        return this.entity;
      } else if (this.label !== null) {
        return this.label;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label-scrap {
  box-shadow: 0 2px 3px rgba(black, 0.3);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(black, 0.02);
}

.label-scrap__left {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  white-space: nowrap;
}

.label-scrap__right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.label-scrap__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  max-width: 100%;
}

.label-scrap__title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 5px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &.--grey {
    color: rgba(128, 128, 128, 0.725);
    font-weight: 700;
  }
}

.label-scrap__label {
  flex-shrink: 0;
}

.label-scrap__detail {
  font-size: 0.8rem;
  font-weight: 300;
  color: grey;
  fill: grey;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  max-width: 100%;

  svg {
    height: 14px;
    margin-right: 4px;
    flex-shrink: 0;
  }

  .label-scrap__address {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.label-scrap__added {
  font-style: italic;
  font-size: 0.9rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-scrap__button {
  margin-left: 6px;
}

@media (max-width: 600px) {
  .label-scrap__title {
    font-size: 1.1rem;
  }
  .label-scrap__button {
    min-width: 0;
    width: 36px;

    .mdc-button__icon {
      margin: 0;
    }

    span {
      display: none;
    }
  }
}
</style>
