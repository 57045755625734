<script setup>
import { ref, onMounted, computed } from "vue";

const callbackUrl = ref("");
const loginLink = computed(
  () =>
    `https://accounts.bubblemaps.io/authenticate/?callback=${
      callbackUrl.value ? callbackUrl.value : ""
    }`
);

onMounted(() => {
  callbackUrl.value = `${window.location.origin}/`;
});
</script>

<template>
  <main>
    <div class="login-wrapper">
      <div>Please log in to access Bubblemaps admin</div>
      <div class="vh-5"></div>
      <div class="button-wrapper">
        <a class="button elevated login-button" :href="loginLink"> Login </a>
      </div>
    </div>
  </main>
</template>
