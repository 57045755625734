<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import AddATokenDialog from "./AddATokenDialog.vue";
import { watch } from "vue";
import StickyTokenSwitcher from "./StickyTokenSwitcher.vue";
import InsertATokenDialog from "./InsertATokenDialog.vue";
import ReplaceATokenDialog from "./replaceATokenDialog.vue";
import EditATokenDialog from "./EditATokenDialog.vue";
import DeleteATokenDialog from "./DeleteATokenDialog.vue";
import BlockchainChanges from "./BlockchainChanges.vue";
import BlockchainTokens from "./BlockchainTokens.vue";
import BlockchainHomepageLists from "./BlockchainHomepageLists.vue";

const tokens = useTokensStore();

function confirmExit() {
  return "You have attempted to leave this page. Are you sure?";
}

watch(
  () => tokens.thereArePendingChanges,
  (thereArePendingChanges) => {
    if (thereArePendingChanges) {
      window.onbeforeunload = confirmExit;
    } else {
      window.onbeforeunload = null;
    }
  }
);
</script>

<template>
  <div>
    <StickyTokenSwitcher />
    <AddATokenDialog />
    <EditATokenDialog />
    <DeleteATokenDialog />
    <InsertATokenDialog />
    <ReplaceATokenDialog />
    <BlockchainChanges />
    <BlockchainTokens />
    <BlockchainHomepageLists />
    <!-- <div class="vh-5"></div> -->
  </div>
</template>
