<script setup>
import { useTokensStore } from "@/stores/tokens.js";

import CenteredDialog from "./CenteredDialog.vue";

const tokens = useTokensStore();
</script>

<template>
  <div v-show="tokens.insertingAToken">
    <CenteredDialog>
      <div>
        <form @submit.prevent="">
          <div>
            <h2>Inserting a Token</h2>
            <div class="text-center">
              to
              {{ tokens.insertionList }} in position
              {{ tokens.insertionPosition + 1 }}
            </div>
            <div v-if="tokens.blockchain && tokens.blockchain.tokens">
              <label for="token_search">token name</label>
              <input
                autocomplete="do-not-autofill"
                required
                :pattern="
                  tokens.tokenToInsertValid
                    ? tokens.tokenToInsert
                    : 'invalidfullname'
                "
                v-model="tokens.tokenToInsert"
                type="text"
                name="token_search"
                id="token_search"
                list="tokenSuggestions"
              />
              <datalist id="tokenSuggestions">
                <option
                  :value="tokenSuggestion.full_name"
                  v-for="(tokenSuggestion, tokenSuggestionI) in tokens
                    .newBlockchain.tokens"
                  :key="tokenSuggestionI"
                ></option>
              </datalist>
            </div>
            <div class="dialog-actions-wrapper">
              <div>
                <button
                  class="elevated"
                  @click="tokens.insertingAToken = false"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  class="elevated primary"
                  :disabled="!tokens.tokenToInsertValid"
                  type="submit"
                  value="Submit"
                  @click="tokens.insertToken()"
                >
                  <div>Insert</div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CenteredDialog>
  </div>
</template>
