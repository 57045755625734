export default {
  fullDate(date) {
    return `${date.toUTCString().slice(0, 22)} GMT`;
  },
  dayDate(date) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  },
};
