<script setup>
import { useWhitelistStore } from "@/stores/whitelist.js";
import { useLoginStore } from "@/stores/login.js";
const whitelist = useWhitelistStore();
const login = useLoginStore();
</script>

<template>
  <div class="whitelisted-element">
    <div class="whitelisted-element__left">
      <div class="whitelisted-element__head">
        <div class="whitelisted-element__title">{{ displayName }}</div>
        <ExpirationLabel
          class="whitelisted-element__label"
          :endDate="endDate"
          :expired="expired"
        />
      </div>
      <div class="whitelisted-element__detail">
        <WhitelistedTypeLogo :type="type" />
        <div class="whitelisted-element__id">{{ id }}</div>
      </div>
      <div class="whitelisted-element__added">
        Added: {{ dtAdded ? $format.fullDate(dtAdded) : "Unknown" }}
      </div>
    </div>
    <div class="whitelisted-element__right">
      <Button
        v-if="!expired"
        outlined
        class="whitelisted-element__button"
        @click="whitelist.revokeWhitelistElement(type, id, login.user.uid)"
      >
        <i class="material-icons mdc-button__icon" aria-hidden="true">
          cancel </i
        ><span>Revoke</span></Button
      >
      <Button
        outlined
        class="whitelisted-element__button"
        @click="$parent.openForm(id, type, { displayName, endDate })"
      >
        <i class="material-icons mdc-button__icon" aria-hidden="true"> edit </i
        ><span>Edit</span></Button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";
import ExpirationLabel from "@/components/atoms/ExpirationLabel.vue";
import WhitelistedTypeLogo from "@/components/atoms/WhitelistedTypeLogo.vue";

export default {
  name: "WhitelistedElement",
  components: {
    Button,
    ExpirationLabel,
    WhitelistedTypeLogo,
  },
  props: {
    displayName: String,
    id: String,
    dtAdded: Date,
    endDate: Date,
    expired: Boolean,
    type: String,
  },
};
</script>

<style lang="scss" scoped>
.whitelisted-element {
  box-shadow: 0 2px 3px rgba(black, 0.3);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(black, 0.02);
}

.whitelisted-element__left {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  white-space: nowrap;
}

.whitelisted-element__right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.whitelisted-element__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  max-width: 100%;
}

.whitelisted-element__title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 5px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.whitelisted-element__label {
  flex-shrink: 0;
}

.whitelisted-element__detail {
  font-size: 0.8rem;
  font-weight: 300;
  color: grey;
  fill: grey;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  max-width: 100%;

  svg {
    height: 14px;
    margin-right: 4px;
    flex-shrink: 0;
  }

  .whitelisted-element__id {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.whitelisted-element__added {
  font-style: italic;
  font-size: 0.9rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.whitelisted-element__button {
  margin-left: 6px;
}

@media (max-width: 600px) {
  .whitelisted-element__title {
    font-size: 1.1rem;
  }
  .whitelisted-element__button {
    min-width: 0;
    width: 36px;

    .mdc-button__icon {
      margin: 0;
    }

    span {
      display: none;
    }
  }
}
</style>
