<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import { nextTick, watch, ref } from "vue";
import CenteredDialog from "./CenteredDialog.vue";

const tokens = useTokensStore();

const deleteATokenDialog = ref(null);

watch(
  () => tokens.deletingAToken,
  (deletingAToken) => {
    if (deletingAToken) {
      nextTick(() => {
        deleteATokenDialog.value.focus();
        document.querySelector("body").style.overflowY = "clip";
      });
    }
    if (!deletingAToken) {
      nextTick(() => {
        document.querySelector("body").style.overflowY = "auto";
      });
    }
  }
);

// TODO watch search_token_to_delete_full_name and update the newEditedToken accordingly
watch(
  () => tokens.search_token_to_delete_full_name,
  (search_token_to_delete_full_name) => {
    if (search_token_to_delete_full_name) {
      var result = tokens?.blockchain?.tokens.find(
        (token) => token.full_name === search_token_to_delete_full_name
      );
      if (result) {
        tokens.newEditedToken = result;
      }
    }
  }
);
</script>
<template>
  <div v-show="tokens.deletingAToken">
    <CenteredDialog>
      <div
        ref="deleteATokenDialog"
        tabindex="-1"
        @keyup.esc="tokens.deletingAToken = false"
        id="deleteATokenDialog"
      >
        <form @submit.prevent="">
          <div>
            <h2>Delete a Token</h2>
            <div v-if="tokens.blockchain && tokens.blockchain.tokens">
              <label for="search_token_to_delete_full_name"
                >search_token_to_delete_full_name</label
              >
              <input
                autocomplete="do-not-autofill"
                required
                :pattern="
                  tokens.search_token_to_delete_full_name_is_valid
                    ? tokens.search_token_to_delete_full_name
                    : 'invalidfullname'
                "
                v-model="tokens.search_token_to_delete_full_name"
                type="text"
                name="search_token_to_delete_full_name"
                id="search_token_to_delete_full_name"
                list="searchTokenToDeleteSuggestions"
              />
              <datalist id="searchTokenToDeleteSuggestions">
                <option
                  :value="tokenSuggestion.full_name"
                  v-for="(tokenSuggestion, tokenSuggestionI) in tokens
                    .newBlockchain.tokens"
                  :key="tokenSuggestionI"
                ></option>
              </datalist>
            </div>
            <div
              class="error-message"
              v-if="!tokens.search_token_to_delete_full_name_is_valid"
            >
              Could not find a token with this name
            </div>
            <div
              class="error-message"
              v-if="tokens.search_token_to_delete_id_cannot_be_deleted"
            >
              List Featured or Most visited or Most recent contains this token,
              to delete it you must first replace it in those lists
            </div>
            <div class="dialog-actions-wrapper">
              <div>
                <button
                  class="elevated"
                  @click="
                    tokens.deletingAToken = false;
                    tokens.search_token_to_delete_full_name = '';
                  "
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  class="elevated primary"
                  :disabled="
                    !tokens.search_token_to_delete_full_name_is_valid ||
                    tokens.search_token_to_delete_id_cannot_be_deleted
                  "
                  type="submit"
                  value="Submit"
                  @click="tokens.deleteToken()"
                >
                  <div>Delete</div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CenteredDialog>
  </div>
</template>
