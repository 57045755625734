<template>
  <Dialog ref="dialog" :defaultWidth="500">
    <div class="mdc-dialog__content address-search-form">
      <h2>Search Address</h2>
      <TextField
        v-model="searchedAddress"
        hintText="Wallet Address"
        class="mdc-text-field--filled address-search-form__field"
        ripple
        ref="input"
      />
      <div
        class="address-search-form__warning"
        v-if="!addressIsValid && searchedAddress.length > 0"
      >
        This should be 42 hexadecimal characters beginning by 0x for EVM chains
        or 32-44 alphanumeric characters for Solana.
      </div>
      <Button
        :disabled="!addressIsValid"
        v-if="!searched"
        outlined
        @click="handleSearch"
        class="address-search-form__button"
      >
        Search
      </Button>
      <div v-else>
        <div v-if="foundAccountId">An account is linked to that address!</div>
        <div v-else>No account is linked to that address yet.</div>
        <Button
          outlined
          v-if="foundAccountId"
          class="address-search-form__button"
          @click="openWhitelistAccount"
        >
          Whitelist Account
        </Button>
        <Button
          outlined
          class="address-search-form__button"
          @click="openWhitelistAddress"
        >
          Whitelist Address
        </Button>
      </div>
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard"> Close </Button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/atoms/material/Dialog.vue";
import Button from "@/components/atoms/material/Button.vue";
import TextField from "@/components/atoms/material/TextField.vue";
import { db } from "@/plugins/firebase.js";
import { doc, getDoc } from "firebase/firestore/lite";

export default {
  name: "AddressSearchForm",
  components: {
    Dialog,
    Button,
    TextField,
  },
  data: () => {
    return {
      searchedAddress: "",
      foundAccountId: null,
      searched: false,
    };
  },
  watch: {
    searchedAddress() {
      this.searched = false;
      this.foundAccountId = null;
    },
  },
  computed: {
    addressIsValid() {
      const regex = new RegExp(
        "^(0x[a-fA-F0-9]{40})|([1-9A-HJ-NP-Za-km-z]{32,44})$"
      );
      return regex.test(this.searchedAddress);
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open();
      this.$refs.input.focus();
    },
    close() {
      this.$refs.dialog.close();
    },
    async handleSearch() {
      try {
        const docRef = doc(
          db,
          "linked_wallet_addresses",
          this.searchedAddress.toLowerCase()
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.searched = true;
          this.foundAccountId = docSnap.data().uid;
        } else {
          this.searched = true;
        }
      } catch (error) {
        alert(
          "Failed to search the address, check your internet connection and try again."
        );
        throw error;
      }
    },
    openWhitelistAddress() {
      const address = this.searchedAddress.toLowerCase();
      this.searchedAddress = "";
      this.close();
      this.$parent.openForm(address, "address", { createNew: true });
    },
    openWhitelistAccount() {
      this.searchedAddress = "";
      this.close();
      this.$parent.openForm(this.foundAccountId, "account", {
        createNew: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.address-search-form {
  text-align: center;
}

.address-search-form__field {
  width: 100%;
}

.address-search-form__warning {
  font-size: 0.8em;
  font-weight: 500;
  color: orange;
}

.address-search-form__button {
  margin: 10px 4px 0 4px;
}
</style>
