<template>
  <div v-if="expired" class="expiration-label --expired">
    <i class="material-icons" aria-hidden="true"> cancel </i
    ><span>Expired</span>
  </div>
  <div v-else-if="!endDate" class="expiration-label --unlimited">
    <i class="material-icons" aria-hidden="true"> check_circle </i>
    <span>No&nbsp;Expiration</span>
  </div>
  <div v-else class="expiration-label --running">
    <i class="material-icons" aria-hidden="true"> schedule </i>
    <span>{{ $format.dayDate(endDate) }}</span>
  </div>
</template>

<script>
export default {
  name: "ExpirationLabel",
  props: {
    endDate: Date,
    expired: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.expiration-label {
  height: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: 0 10px 0 4px;
  font-size: 0.8em;

  .material-icons {
    font-size: 18px;
  }

  &.--unlimited {
    border-color: green;
    color: green;
  }

  &.--running {
    border-color: orange;
    color: orange;
  }

  &.--expired {
    border-color: red;
    color: red;
  }
}

@media (max-width: 600px) {
  .expiration-label {
    width: 24px;
    padding: 0;
    justify-content: center;

    span {
      display: none;
    }
  }
}
</style>
