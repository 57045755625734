<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import { onMounted, ref } from "vue";
const tokens = useTokensStore();

const onFilePicked = function (ev) {
  tokens.file = ev.target.files[0];
  tokens.previewUrl = URL.createObjectURL(tokens.file);
};

const tokenLogoFilePicker = ref(null);

onMounted(() => {
  tokens.previewUrl = null;
});

const isDraggingOver = ref(false);

const onDropHandler = function (ev) {
  isDraggingOver.value = false;
  ev.preventDefault();
  const dt = ev.dataTransfer;
  const files = dt.files;
  if (files.length > 0) {
    tokens.file = files[0];
    tokens.previewUrl = URL.createObjectURL(tokens.file);
  }
};

const onDragOverHandler = function (ev) {
  isDraggingOver.value = true;
  ev.preventDefault();
};

const onDragLeaveHandler = function (ev) {
  ev.preventDefault();
  isDraggingOver.value = false;
};
</script>

<template>
  <div>
    <label for="tokenLogoFilePicker"> image* </label>
    <input
      ref="tokenLogoFilePicker"
      type="file"
      id="tokenLogoFilePicker"
      @change="onFilePicked"
      accept="image/png, image/jpeg"
    />
  </div>
  <!-- TODO create a drop zone for file drag and drop -->
  <label for="tokenLogoFilePicker">
    <div
      :class="`dropzone ${isDraggingOver ? 'isDraggingOver' : ''}`"
      @drop="onDropHandler"
      @dragover="onDragOverHandler"
      @dragleave="onDragLeaveHandler"
    >
      <div>
        <div class="d-flex justify-center">
          <span class="material-icons"> file_upload </span>
        </div>
        <div class="text-center">
          Click and select <br />
          or <br />
          Drop an image here
        </div>
      </div>
    </div>
  </label>
  <div class="d-flex justify-center">
    <div v-if="tokens.previewUrl">
      <img class="avatar imagePreview" :src="tokens.previewUrl" />
    </div>
    <div class="error-message" v-if="tokens.addingAToken && !tokens.previewUrl">
      Please select an image
    </div>
  </div>
</template>
