<template>
  <!-- Dialogs -->
  <QueueScrapForm ref="queueScrapForm" class="--temp-prevent-broken-style" />
  <!-- Page Content -->
  <main class="pageContent --temp-prevent-broken-style">
    <div>
      <h1 class="labels__title">Queued Labels Scraps</h1>
      <div class="labels__buttons">
        <Button
          outlined
          class="labels__button"
          @click="$refs.queueScrapForm.open()"
        >
          <i class="material-icons mdc-button__icon" aria-hidden="true">
            add_circle </i
          ><span>Queue Scrap</span></Button
        >
      </div>
      <Loader
        v-if="this.labelsStore.queuedScraps_loading"
        :size="60"
        class="labels__loader"
      />
      <div
        v-else-if="this.labelsStore.queuedScraps.length == 0"
        class="labels__empty_queue"
      >
        No queued scrap
      </div>
      <template v-else>
        <div class="labels__precision">
          Scraps are performed every 10mins between 7-12 and 14-22 CET
        </div>
        <QueuedScrap
          v-for="el in queued_scraps"
          :key="el.id"
          :id="el.requested_id ? el.requested_id : null"
          :address="el.address"
          :chain="el.chain"
          :dtAdded="el.dt_added ? el.dt_added.toDate() : null"
          class="labels__element"
        />
      </template>
    </div>
    <h1 class="labels__title">Last 24h Labels Scraps</h1>
    <Loader
      v-if="this.labelsStore.labelsScraps_loading"
      :size="60"
      class="labels__loader"
    />
    <div
      v-else-if="this.labelsStore.labelsScraps.length == 0"
      class="labels__empty_queue"
    >
      No recently scraped label
    </div>
    <template v-else>
      <div class="labels__precision">
        {{ this.labelsStore.labelsScraps.length }} recently scraped labels
      </div>
      <LabelScrap
        v-for="el in label_scraps"
        :key="el.id"
        :address="el.address"
        :label="el.label"
        :dtUpdate="el.dt_update ? el.dt_update.toDate() : null"
        :etherscan_name="el.etherscan_name"
        :entity="el.entity"
        class="labels__element"
      />
    </template>
  </main>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";
import LabelScrap from "@/components/molecules/LabelScrap.vue";
import QueuedScrap from "@/components/molecules/QueuedScrap.vue";
import QueueScrapForm from "@/components/molecules/QueueScrapForm.vue";
import Loader from "@/components/atoms/Loader.vue";
import { mapStores } from "pinia";
import { useLabelsStore } from "@/stores/labels.js";

export default {
  name: "LabelsView",
  components: {
    LabelScrap,
    QueuedScrap,
    QueueScrapForm,
    Loader,
    Button,
  },
  computed: {
    ...mapStores(useLabelsStore),
    queued_scraps() {
      return this.labelsStore.queuedScraps
        .map((el) => {
          return el;
        })
        .sort((a, b) => {
          if (!("dt_added" in a) && !("dt_added" in b)) {
            return 0;
          } else if (!("dt_added" in a)) {
            return 1;
          } else if (!("dt_added" in b)) {
            return -1;
          } else {
            return a.dt_added - b.dt_added;
          }
        });
    },
    label_scraps() {
      return this.labelsStore.labelsScraps
        .map((el) => {
          return el;
        })
        .sort((a, b) => {
          if (!("dt_update" in a) && !("dt_update" in b)) {
            return 0;
          } else if (!("dt_update" in a)) {
            return 1;
          } else if (!("dt_update" in b)) {
            return -1;
          } else {
            return b.dt_update - a.dt_update;
          }
        });
    },
  },
  mounted: function () {
    this.labelsStore.getQueuedScraps();
    this.labelsStore.getFreshLabelsScraps();
    this.intervalId = setInterval(() => {
      this.labelsStore.getQueuedScraps();
      this.labelsStore.getFreshLabelsScraps();
      console.log("test");
    }, 60000);
  },
  beforeUnmount() {
    // Clear the interval when the component is about to be destroyed to avoid memory leaks
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss" scoped>
.labels__title {
  text-align: center;
}

.labels__empty_queue {
  text-align: center;
  font-style: italic;
}

.labels__precision {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.labels__buttons {
  text-align: center;
  margin-bottom: 20px;
}

.labels__button {
  margin: 0 4px 10px 4px;
}

.labels__loader {
  margin: auto;
}

.labels__element {
  margin: 0 10px 10px 10px;
}
</style>
