<script setup>
import app from "./plugins/firebase.js";
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
} from "firebase/auth";
import { useLoginStore } from "@/stores/login.js";

import { useRouter } from "vue-router";
const login = useLoginStore();

const router = useRouter();
// computed

const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  if (!user) {
    if (router.currentRoute.value.name != "login") {
      router.push({ path: "/login" });
    }
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (token) {
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          login.$patch({
            user: userCredential.user,
          });
        })
        .catch((error) => {
          throw new Error(error);
        });
      // cleanup
      url.searchParams.delete("token");
      history.replaceState({}, document.title, url);
    }
  } else if (user) {
    login.$patch({
      user: user,
    });
    // if current route is login redirect to /
    if (router.currentRoute.value.name === "login") {
      router.push({ path: "/" });
    }
  }
});
</script>

<template>
  <RouterView name="AppBar" />
  <RouterView name="NavigationDrawer" />
  <RouterView />
</template>

<style lang="scss">
@use "sass:map";
@use "@material/theme" with (
  $primary: #8d26d8,
  $secondary: #de219a
);
@import "assets/variables";

// Import styles for MDC Web components
@import "@material/ripple/mdc-ripple";
@import "@material/button/mdc-button";
@import "@material/icon-button/mdc-icon-button";
@import "@material/dialog/mdc-dialog";
@import "@material/textfield/mdc-text-field";

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
