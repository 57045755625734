import { defineStore } from "pinia";
import { db } from "@/plugins/firebase.js";
import { doc, getDoc, Timestamp, setDoc } from "firebase/firestore/lite";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { storage } from "@/plugins/firebase.js";
import { v4 as uuidv4 } from "uuid";

export const useTokensStore = defineStore("tokens", {
  state: () => {
    return {
      changes: [],
      search_token_to_delete_full_name: "",
      search_full_name: "",
      publishChangesLoading: false,
      file: null,
      previewUrl: null,
      editingTokens: true,
      config: process.env,
      addingAToken: false,
      editingAToken: false,
      deletingAToken: false,
      replacingAToken: false,
      insertingAToken: false,
      insertionList: "",
      insertionPosition: 0,
      newEditedToken: {
        place_after: 0,
        place_after_full_name: "",
        address: "",
        full_name: "",
        is_soon: false,
        is_moonscan: false,
        is_rugged: false,
        website: "https://examplewebsite.com",
        youtube: "",
        image: "",
      },
      newToken: {
        place_after: 0,
        place_after_full_name: "",
        address:
          process.env.NODE_ENV == "development"
            ? "0x1234567890123456789012345678901234567890"
            : "",
        full_name:
          process.env.NODE_ENV == "development" ? "Hello Example Token" : "",
        is_soon: false,
        is_moonscan: false,
        is_rugged: false,
        website:
          process.env.NODE_ENV == "development"
            ? "https://examplewebsite.com"
            : "",
        youtube: "",
        image: "",
      },
      newLists: {
        featured: [],
        recently_listed: [],
        most_visited: [],
      },
      maxTokens: {
        featured: 4,
        recently_listed: 5,
        most_visited: 5,
      },
      blockchain: null,
      selectedBlockchainId: "",
      supportedBlockchains: [
        `tokens_bsc`,
        `tokens_eth`,
        `tokens_avax`,
        `tokens_ftm`,
        `tokens_cro`,
        `tokens_arbi`,
        `tokens_poly`,
        `tokens_base`,
        `tokens_sol`,
        `tokens_sonic`,
      ],
    };
  },
  getters: {
    search_token_to_delete_id(state) {
      if (!this.newBlockchain) {
        return "";
      }
      return (
        this.newBlockchain?.tokens.find(
          (t) => t.full_name === state.search_token_to_delete_full_name
        )?.id || ""
      );
    },
    search_token_to_delete_id_cannot_be_deleted(state) {
      if (!this.newBlockchain) {
        return false;
      }
      return (
        this.newBlockchain.featured.includes(state.search_token_to_delete_id) ||
        this.newBlockchain.recently_listed.includes(
          state.search_token_to_delete_id
        ) ||
        this.newBlockchain.most_visited.includes(
          state.search_token_to_delete_id
        )
      );
    },
    newBlockchainTokens: (state) => {
      var newBlockchainTokens = state.blockchain?.tokens;
      if (state.changes.length === 0) {
        return newBlockchainTokens;
      }
      for (var i = 0; i < state.changes.length; i++) {
        if (state.changes[i].type === "added") {
          newBlockchainTokens = newBlockchainTokens.map((t, index) => ({
            ...t,
            place_after: index,
          }));
          newBlockchainTokens.push(state.changes[i].token);
          newBlockchainTokens = newBlockchainTokens.slice().sort((a, b) => {
            return a.place_after - b.place_after;
          });
        }
        if (state.changes[i].type === "deleted") {
          newBlockchainTokens = newBlockchainTokens.filter(
            (t) => t.id !== state.changes[i].token.id
          );
        }
        if (state.changes[i].type === "edited") {
          newBlockchainTokens = newBlockchainTokens.map((t) => {
            if (t.id === state.changes[i].token.id) {
              return state.changes[i].token;
            }
            return t;
          });
          newBlockchainTokens = newBlockchainTokens.slice().sort((a, b) => {
            return a.place_after - b.place_after;
          });
        }
      }
      newBlockchainTokens = newBlockchainTokens.map(
        // do not fix: this is to remove the
        // place_after and place_after_full_name properties
        // eslint-disable-next-line no-unused-vars
        ({ place_after, place_after_full_name, ...t }) => t
      );
      return newBlockchainTokens;
    },
    newBlockchain: (state) => {
      if (!state.blockchain) {
        return null;
      }
      return {
        ...state.blockchain,
        tokens: state.newBlockchainTokens,
        featured:
          state.newLists.featured.length > 0
            ? state.newLists.featured
            : state.blockchain.featured,
        recently_listed:
          state.newLists.recently_listed.length > 0
            ? state.newLists.recently_listed
            : state.blockchain.recently_listed,
        most_visited:
          state.newLists.most_visited.length > 0
            ? state.newLists.most_visited
            : state.blockchain.most_visited,
      };
    },
    editedToken_place_after_position(state) {
      return state?.newBlockchain?.tokens
        .map((t) => t.full_name)
        .indexOf(state.newEditedToken.place_after_full_name);
    },
    place_after_position(state) {
      return state?.newBlockchain?.tokens
        .map((t) => t.full_name)
        .indexOf(state.newToken.place_after_full_name);
    },
    thereArePendingChanges(state) {
      return state.changes.length > 0 || this.newListsAreDifferentFromOldLists;
    },
    newListsAreDifferentFromOldLists(state) {
      if (!this.newBlockchain) {
        return false;
      }
      return (
        JSON.stringify(this.newBlockchain?.featured) !==
          JSON.stringify(state.blockchain?.featured) ||
        JSON.stringify(this.newBlockchain?.recently_listed) !==
          JSON.stringify(state.blockchain?.recently_listed) ||
        JSON.stringify(this.newBlockchain?.most_visited) !==
          JSON.stringify(state.blockchain?.most_visited)
      );
    },
    search_full_name_is_valid(state) {
      return (
        this.newBlockchain?.tokens
          .map((t) => t.full_name)
          .includes(state.search_full_name) || false
      );
    },
    search_token_to_delete_full_name_is_valid(state) {
      return (
        this.newBlockchain?.tokens
          .map((t) => t.full_name)
          .includes(state.search_token_to_delete_full_name) || false
      );
    },
    editedToken_place_after_full_name_valid(state) {
      return (
        this.newBlockchain?.tokens
          .map((t) => t.full_name)
          .includes(state.newEditedToken.place_after_full_name) || false
      );
    },
    place_after_full_name_valid(state) {
      return (
        this.newBlockchain?.tokens
          .map((t) => t.full_name)
          .includes(state.newToken.place_after_full_name) || false
      );
    },
    tokenToInsertValid(state) {
      return (
        this.newBlockchain?.tokens
          .map((t) => t.full_name)
          .includes(state.tokenToInsert) || false
      );
    },
    full_name_valid(state) {
      return !this.newBlockchain?.tokens
        .map((t) => t.full_name)
        .includes(state.newToken.full_name);
    },
  },
  actions: {
    tokenId(token_full_name) {
      return (
        this.newBlockchain?.tokens.find((t) => t.full_name === token_full_name)
          .id || null
      );
    },
    async updateRemoteBlockchain() {
      try {
        const collectionName = `bubble_maps_metadata${
          this.config.NODE_ENV == "development" ? "_DEV" : ""
        }`;
        await setDoc(
          doc(db, collectionName, this.selectedBlockchainId),
          this.newBlockchain
        );

        return 1;
      } catch (error) {
        throw new Error("Failed to updateRemoteBlockchain.");
      }
    },
    async publishChanges() {
      this.publishChangesLoading = true;
      if (confirm("Are you sure you want to publish changes?") == true) {
        try {
          await this.updateRemoteBlockchain();
          this.resetChanges(false);
          await this.setLocalBlockchain();
          this.publishChangesLoading = false;
          alert("Changes published!");
        } catch (error) {
          this.publishChangesLoading = false;
          alert("Failed to publish changes.");
          throw new Error("Failed to publish changes.");
        }
      } else {
        alert("You pressed Cancel!");
        this.publishChangesLoading = false;
      }
    },
    async addEditedTokenToChanges() {
      try {
        if (this.previewUrl) {
          this.newEditedToken.image = await this.uploadPickedFile();
        }
        var token = {
          ...this.newEditedToken,
          place_after: this.editedToken_place_after_position,
          address:
            this.selectedBlockchainId == "tokens_sol"
              ? this.newEditedToken.address
              : this.newEditedToken.address.toLowerCase(),
        };
        this.addChangeToChanges(token, "edited");
        this.file = null;
        this.previewUrl = null;
        this.resetEditedToken();
        this.editingAToken = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    resetEditedToken() {
      this.search_full_name = "";
      this.newEditedToken = {
        place_after: 0,
        place_after_full_name: "",
        address: "",
        full_name: "",
        is_soon: false,
        is_moonscan: false,
        is_rugged: false,
        website: "",
        youtube: "",
        image: "",
      };
    },
    resetNewToken() {
      this.newToken = {
        place_after: 0,
        place_after_full_name: "",
        address: "",
        full_name: "",
        is_soon: false,
        is_moonscan: false,
        is_rugged: false,
        website: "",
        youtube: "",
        image: "",
      };
    },
    async addNewTokenToChanges() {
      try {
        this.newToken.image = await this.uploadPickedFile();
        var token = {
          ...this.newToken,
          place_after: this.place_after_position,
          address:
            this.selectedBlockchainId == "tokens_sol"
              ? this.newToken.address
              : this.newToken.address.toLowerCase(),
          id: uuidv4(),
          // TODO verify firebase timestamp works
          dt_added: Timestamp.now(),
        };
        this.addChangeToChanges(token, "added");
        this.file = null;
        this.previewUrl = null;
        this.resetNewToken();
        this.addingAToken = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getBlockchain(blockchainId) {
      const collectionName = `bubble_maps_metadata${
        this.config.NODE_ENV == "development" ? "_DEV" : ""
      }`;
      const docRef = doc(db, collectionName, blockchainId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        const blockchain = docSnap.data();
        return blockchain;
      } else {
        return null;
      }
    },
    async setLocalBlockchain() {
      const blockchain = await this.getBlockchain(this.selectedBlockchainId);
      this.blockchain = blockchain;
    },
    async uploadPickedFile() {
      const newStorageRef = storageRef(
        storage,
        `app/tokens-images/${uuidv4()}`
      );
      try {
        const snapshot = await uploadBytes(newStorageRef, this.file);

        return snapshot.ref.name;
      } catch (error) {
        throw new Error("Failed to upload file.");
      }
    },
    resetChanges(askForConfirmation = true) {
      if (!askForConfirmation) {
        this.changes = [];
        this.newLists = {
          featured: [],
          recently_listed: [],
          most_visited: [],
        };
        return;
      }
      if (askForConfirmation) {
        if (confirm("Are you sure you want to reset changes?") == true) {
          this.changes = [];
          this.newLists = {
            featured: [],
            recently_listed: [],
            most_visited: [],
          };
        }
      }
    },
    undoChanges() {
      this.changes = [...this.changes.slice(0, -1)];
    },
    insertToken() {
      this.newLists[this.insertionList] = [
        ...this.newBlockchain[this.insertionList].slice(
          0,
          this.insertionPosition
        ),
        this.tokenId(this.tokenToInsert),
        ...this.newBlockchain[this.insertionList].slice(this.insertionPosition),
      ].slice(0, this.maxTokens[this.insertionList]);
      this.insertingAToken = false;
      this.insertionList = "";
      this.insertionPosition = 0;
      this.tokenToInsert = "";
    },
    replaceToken() {
      this.newLists[this.insertionList] = [
        ...this.newBlockchain[this.insertionList].slice(
          0,
          this.insertionPosition
        ),
        this.tokenId(this.tokenToInsert),
        ...this.newBlockchain[this.insertionList].slice(
          this.insertionPosition + 1
        ),
      ].slice(0, this.maxTokens[this.insertionList]);
      this.replacingAToken = false;
      this.insertionList = "";
      this.insertionPosition = 0;
      this.tokenToInsert = "";
    },
    addChangeToChanges(token, type) {
      this.changes.push({
        token: token,
        type: type,
        changedAt: Date.now(),
      });
    },
    deleteToken() {
      const token_to_delete_object = this.newBlockchain.tokens.find(
        (t) => t.full_name === this.search_token_to_delete_full_name
      );
      this.addChangeToChanges(token_to_delete_object, "deleted");
      this.deletingAToken = false;
      this.search_token_to_delete_full_name = "";
    },
  },
});

export default useTokensStore;
