<template>
  <div class="d-flex justify-center align-center" v-show="tokens.editingTokens">
    <div class="horizontal-line"></div>
    <div class="button-wrapper">
      <button
        class="iconButton"
        @click="
          tokens.insertingAToken = true;
          tokens.insertionList = tokenList;
          tokens.insertionPosition = tokenPosition;
        "
      >
        <span class="material-icons"> add_circle_outline </span>
      </button>
    </div>
  </div>
  <div
    :class="`listElement d-flex justify-space-between align-center ${
      positionDiff ? 'highlighted-background' : ''
    }`"
  >
    <div v-if="token" class="d-flex align-center">
      <TokenImage :imageId="token.image" />
      {{ token.full_name }}

      <span class="position">
        {{ isNew ? "(new)" : `${positionDiff ? `(${positionDiff})` : ""}` }}
      </span>
    </div>
    <button
      class="iconButton"
      @click="
        tokens.replacingAToken = true;
        tokens.insertionList = tokenList;
        tokens.insertionPosition = tokenPosition;
      "
    >
      <span class="material-icons"> edit </span>
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { useTokensStore } from "@/stores/tokens";
import TokenImage from "./TokenImage.vue";
const tokens = useTokensStore();
const props = defineProps(["tokenId", "tokenList", "tokenPosition"]);

const token = computed(() =>
  tokens.newBlockchain.tokens.find((t) => t.id === props.tokenId)
);

const isNew = computed(
  () => !tokens.blockchain[props.tokenList].includes(props.tokenId)
);

const positionDiff = computed(
  () =>
    tokens.blockchain[props.tokenList].indexOf(props.tokenId) -
    props.tokenPosition
);
</script>

<style>
.highlighted-background {
  background-color: #fff9e7;
}
</style>
