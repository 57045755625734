<script setup>
import { useTokensStore } from "@/stores/tokens.js";

import { nextTick, watch } from "vue";

const tokens = useTokensStore();

watch(
  () => tokens.selectedBlockchainId,
  (selectedBlockchainId) => {
    if (selectedBlockchainId) {
      nextTick(() => {
        tokens.setLocalBlockchain();
      });
    }
  }
);
</script>

<template>
  <div id="stickyTokenSwitcher">
    <div class="sticky-token-switcher-actions-wrapper">
      <div>
        <select
          :disabled="tokens.thereArePendingChanges"
          name="tokenNameSelect"
          id="tokenNameSelect"
          v-model="tokens.selectedBlockchainId"
        >
          <option value="">--Select--</option>
          <option
            :value="token"
            v-for="(token, tokenI) in tokens.supportedBlockchains"
            :key="tokenI"
          >
            {{ token }}
          </option>
        </select>
      </div>
      <div>
        <button
          :disabled="
            tokens.publishChangesLoading || !tokens.thereArePendingChanges
          "
          class="elevated"
          @click="tokens.resetChanges()"
        >
          <div>Reset Changes</div>
        </button>
      </div>
      <!-- TODO: Uncomment for undo functionality
        It only works for added, edited, and deleted tokens
        It doesn't work on lists like featured, most visited, recently listed
      -->

      <!-- <div>
        <button
          class="iconButton"
          @click="tokens.undoChanges()"
          :disabled="
            tokens.publishChangesLoading || !tokens.thereArePendingChanges
          "
        >
          <span class="material-icons"> undo </span>
        </button>
      </div> -->
      <div>
        <button
          class="elevated"
          :disabled="
            tokens.publishChangesLoading || !tokens.thereArePendingChanges
          "
          @click="tokens.publishChanges()"
        >
          <div v-if="!tokens.publishChangesLoading">Publish Changes</div>
          <div class="lds-ring" v-if="tokens.publishChangesLoading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
