<template>
  <button
    :class="
      'mdc-button' +
      (raised ? ' mdc-button--raised' : '') +
      (outlined ? ' mdc-button--outlined' : '')
    "
  >
    <a v-if="link" :href="link" :target="target">
      <div class="mdc-button__ripple"></div>
      <slot></slot>
    </a>
    <router-link v-else-if="route" :to="route">
      <div class="mdc-button__ripple"></div>
      <slot></slot>
    </router-link>
    <div v-else>
      <div class="mdc-button__ripple"></div>
      <slot></slot>
    </div>
  </button>
</template>

<script>
import { MDCRipple } from "@material/ripple/index";

export default {
  name: "MaterialButton",
  props: {
    link: String,
    route: String,
    target: String,
    raised: Boolean,
    outlined: Boolean,
  },
  data() {
    return {
      mdcComponent: undefined,
    };
  },
  mounted: function () {
    this.mdcComponent = new MDCRipple(this.$el);
  },
  unmounted: function () {
    this.mdcComponent.destroy();
  },
};
</script>

<style scoped lang="scss">
@use "@material/button";

.mdc-button {
  @include button.shape-radius(50%);
}

.mdc-button:hover::before {
  opacity: 0.15;
}

.mdc-button:disabled {
  color: white !important;
  background-color: grey !important;
}
</style>
