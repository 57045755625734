import { createRouter, createWebHistory } from "vue-router";
import TokensView from "../views/TokensView.vue";
import LoginView from "../views/LoginView.vue";
import WhitelistView from "../views/WhitelistView.vue";
import LabelsView from "../views/LabelsView.vue";

// import useLoginStore from "@/stores/login.js";
import useTokensStore from "@/stores/tokens.js";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "tokens",
      meta: { title: "Tokens" },
      components: {
        default: TokensView,
        NavigationDrawer: () => import("../components/NavigationDrawer.vue"),
        AppBar: () => import("../components/AppBar.vue"),
      },
    },
    {
      path: "/login",
      name: "login",
      meta: { title: "Login" },
      component: LoginView,
    },
    {
      path: "/whitelist",
      name: "whitelist",
      meta: { title: "Whitelist" },
      components: {
        default: WhitelistView,
        NavigationDrawer: () => import("../components/NavigationDrawer.vue"),
        AppBar: () => import("../components/AppBar.vue"),
      },
    },
    {
      path: "/labels",
      name: "labels",
      meta: { title: "Labels" },
      components: {
        default: LabelsView,
        NavigationDrawer: () => import("../components/NavigationDrawer.vue"),
        AppBar: () => import("../components/AppBar.vue"),
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const tokens = useTokensStore();
  // TODO: prevent people from leaving /tokens if there are changes that weren't published
  if (
    from.name === "tokens" &&
    to.name !== "tokens" &&
    tokens.thereArePendingChanges
  ) {
    if (
      confirm(
        "You have unpublished changes. Are you sure you want to leave this page?"
      ) == true
    ) {
      next();
    } else {
      return false;
    }
  }
  document.title = to.meta.title + " | Bubblemaps Admin";
  next();
});

export default router;
