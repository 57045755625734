<template>
  <Dialog v-if="id" ref="dialog" :defaultWidth="500">
    <div class="mdc-dialog__content whitelisted-element-form">
      <h2>
        <span v-if="createNew">Add</span><span v-else>Edit</span> Whitelist
        Entry
      </h2>
      <div class="whitelisted-element-form__detail">
        <WhitelistedTypeLogo :type="type" />
        <div class="whitelisted-element-form__id">{{ id }}</div>
      </div>
      <TextField
        v-model="displayName"
        hintText="Description"
        class="mdc-text-field--filled whitelisted-element-form__field"
        ripple
        ref="input"
      />
      <div class="whitelisted-element-form__date">
        <div class="whitelisted-element-form__date-message">
          <span v-if="endDate">Expires on {{ endDate }}</span
          ><span v-else>No expiration date</span>
        </div>
        <DatePicker ref="datepicker" v-model="endDate" />
      </div>
      <Button
        :disabled="displayName.length == 0"
        raised
        @click="handleConfirm"
        class="whitelisted-element-form__button"
      >
        Confirm
      </Button>
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard"> Close </Button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/atoms/material/Dialog.vue";
import Button from "@/components/atoms/material/Button.vue";
import TextField from "@/components/atoms/material/TextField.vue";
import DatePicker from "@/components/atoms/DatePicker.vue";
import WhitelistedTypeLogo from "@/components/atoms/WhitelistedTypeLogo.vue";
import { mapStores } from "pinia";
import { useWhitelistStore } from "@/stores/whitelist.js";
import { useLoginStore } from "@/stores/login.js";
import { Timestamp } from "firebase/firestore/lite";

export default {
  name: "WhitelistedElementForm",
  components: {
    Dialog,
    Button,
    TextField,
    DatePicker,
    WhitelistedTypeLogo,
  },
  data: () => {
    return {
      id: "Undefined",
      displayName: "",
      endDate: null,
      type: null,
      createNew: false,
    };
  },
  computed: {
    ...mapStores(useWhitelistStore, useLoginStore),
    endDateTimestamp() {
      if (this.endDate) {
        const dateObject = new Date(this.endDate);
        return new Timestamp(dateObject.getTime() / 1000, 0);
      } else {
        return null;
      }
    },
  },
  methods: {
    open(id, type, { displayName = "", endDate = null, createNew = false }) {
      this.id = id;
      this.type = type;
      this.displayName = displayName;
      this.createNew = createNew;
      this.endDate = endDate ? this.$format.dayDate(endDate) : null;
      this.$refs.datepicker.initDate(this.endDate);
      this.$refs.dialog.open();
      this.$refs.input.focus();
    },
    close() {
      this.$refs.dialog.close();
    },
    async handleConfirm() {
      try {
        const data = {
          note: this.displayName,
          end_date: this.endDateTimestamp,
        };
        const authorId = this.loginStore.user.uid;
        if (this.createNew) {
          await this.whitelistStore.commitCreate(
            this.type,
            this.id,
            authorId,
            data
          );
        } else {
          await this.whitelistStore.commitUpdate(
            this.type,
            this.id,
            authorId,
            data
          );
        }
        alert("Success!");
        this.close();
      } catch (error) {
        alert(
          "Failed to commit to DB, check your internet connection and try again."
        );
        throw error;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.whitelisted-element-form {
  text-align: center;
}

.whitelisted-element-form__detail {
  font-size: 1rem;
  font-weight: 300;
  color: grey;
  fill: grey;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  max-width: 100%;

  svg {
    height: 16px;
    margin-right: 6px;
    flex-shrink: 0;
  }

  .whitelisted-element-form__id {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.whitelisted-element-form__field {
  width: 100%;
}

.whitelisted-element-form__button {
  margin-top: 10px;
}

.whitelisted-element-form__date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.whitelisted-element-form__date-message {
  font-weight: 700;
  margin-right: 5px;
}
</style>
