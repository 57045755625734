import { defineStore } from "pinia";

export const useLoginStore = defineStore("login", {
  state: () => {
    return {
      user: null,
    };
  },

  actions: {},
});

export default useLoginStore;
