import { defineStore } from "pinia";
import { db } from "@/plugins/firebase.js";
import {
  getDocs,
  collection,
  updateDoc,
  doc,
  setDoc,
  Timestamp,
} from "firebase/firestore/lite";

export const useWhitelistStore = defineStore("whitelist", {
  state: () => {
    return {
      whitelistedAccounts: [],
      whitelistedAddresses: [],
    };
  },

  actions: {
    async getWhitelist() {
      const accountsQuery = await getDocs(
        collection(db, "whitelisted_accounts")
      );
      this.whitelistedAccounts = accountsQuery.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const addressesQuery = await getDocs(
        collection(db, "whitelisted_addresses")
      );
      this.whitelistedAddresses = addressesQuery.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    },
    async commitUpdate(type, id, authorId, newdata) {
      const collectionName =
        type == "account" ? "whitelisted_accounts" : "whitelisted_addresses";
      await updateDoc(doc(db, collectionName, id), {
        ...newdata,
        last_edit_dt: Timestamp.now(),
        last_edit_id: authorId,
      });
      const dataName =
        type == "account" ? "whitelistedAccounts" : "whitelistedAddresses";
      this[dataName] = this[dataName].map((el) =>
        el.id == id ? { ...el, ...newdata } : el
      );
    },
    async commitCreate(type, id, authorId, newdata) {
      const collectionName =
        type == "account" ? "whitelisted_accounts" : "whitelisted_addresses";
      const nowTimestamp = Timestamp.now();
      await setDoc(doc(db, collectionName, id), {
        ...newdata,
        dt_added: nowTimestamp,
        author_id: authorId,
        last_edit_dt: nowTimestamp,
        last_edit_id: authorId,
      });
      const dataName =
        type == "account" ? "whitelistedAccounts" : "whitelistedAddresses";
      this[dataName].push({ id: id, dt_added: nowTimestamp, ...newdata });
    },
    async revokeWhitelistElement(type, id, authorId) {
      if (
        confirm(
          "Are you sure you want to revoke whitelisting for this user?"
        ) == true
      ) {
        try {
          await this.commitUpdate(type, id, authorId, {
            end_date: Timestamp.now(),
          });
          alert("Whitelisting revoked.");
        } catch (error) {
          alert(
            "Failed to commit the changes, check your internet connection and try again."
          );
          throw error;
        }
      }
    },
  },
});

export default useWhitelistStore;
