<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import TokenImage from "./TokenImage.vue";
const tokens = useTokensStore();
</script>

<template>
  <div class="d-flex justify-center mt-2">
    <div class="fillWidth">
      <div class="token-actions-wrapper">
        <button
          class="elevated"
          :disabled="!tokens.selectedBlockchainId"
          @click="tokens.addingAToken = true"
        >
          <div>Add a Token</div>
        </button>
        <button
          class="elevated"
          :disabled="!tokens.selectedBlockchainId"
          @click="tokens.editingAToken = true"
        >
          <div>Edit a Token</div>
        </button>
        <button
          class="elevated"
          :disabled="!tokens.selectedBlockchainId"
          @click="tokens.deletingAToken = true"
        >
          <div>Delete a Token</div>
        </button>
      </div>
      <div v-if="!tokens.selectedBlockchainId">
        <div class="vh-5"></div>
        <div class="text-center">Please select a blockchain</div>
      </div>
      <div v-if="tokens.thereArePendingChanges">
        <div>
          <h2>Pending changes</h2>
        </div>
        <div class="text-center" v-if="tokens.newListsAreDifferentFromOldLists">
          There are changes in the homepage lists
        </div>
        <div class="d-flex justify-center">
          <div>
            <div
              class="px-1 d-flex align-center"
              v-for="(change, changeI) in tokens.changes
                .slice()
                .sort((a, b) => b.changedAt - a.changedAt)"
              :key="changeI"
            >
              <div>
                {{ change.type }}
              </div>
              <TokenImage :imageId="change.token.image" />
              <div v-if="change.token.full_name">
                {{ change.token.full_name }},
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
