<template>
  <Dialog ref="dialog" :defaultWidth="500">
    <div class="mdc-dialog__content queue-scrap-form">
      <h2>Queue map labels scrap</h2>
      <TextField
        v-model="chain"
        hintText="Chain"
        class="mdc-text-field--filled queue-scrap-form__field"
        ripple
        ref="input"
      />
      <TextField
        v-model="address"
        hintText="Token Address"
        class="mdc-text-field--filled queue-scrap-form__field"
        ripple
      />
      <TextField
        v-model="mapId"
        hintText="Map Id (optional)"
        class="mdc-text-field--filled queue-scrap-form__field"
        ripple
      />
      <div
        class="queue-scrap-form__warning"
        v-if="!addressIsValid && address.length > 0"
      >
        The address should be 42 hexadecimal characters beginning by 0x.
      </div>
      <div
        class="queue-scrap-form__warning"
        v-if="!chainIsValid && chain.length > 0"
      >
        Chain should be eth, bsc, ftm, avax, cro, arbi or poly.
      </div>
      <div
        class="queue-scrap-form__warning"
        v-if="!mapIdIsValid && mapId.length > 0"
      >
        The map Id should be 20 alphanumeric characters.
      </div>
      <Button
        :disabled="
          !addressIsValid ||
          !chainIsValid ||
          (!mapIdIsValid && mapId.length > 0)
        "
        outlined
        @click="handleConfirm"
        class="queue-scrap-form__button"
      >
        Queue Scrap
      </Button>
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard"> Close </Button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/atoms/material/Dialog.vue";
import Button from "@/components/atoms/material/Button.vue";
import TextField from "@/components/atoms/material/TextField.vue";
import { useLoginStore } from "@/stores/login.js";
import { useLabelsStore } from "@/stores/labels.js";
import { mapStores } from "pinia";

export default {
  name: "QueuedScrapForm",
  components: {
    Dialog,
    Button,
    TextField,
  },
  data: () => {
    return {
      address: "",
      chain: "",
      requested_id: "",
      mapId: "",
    };
  },
  computed: {
    ...mapStores(useLoginStore, useLabelsStore),
    addressIsValid() {
      const regex = new RegExp("^0x[a-fA-F0-9]{40}$");
      return regex.test(this.address);
    },
    chainIsValid() {
      return ["eth", "bsc", "ftm", "avax", "cro", "arbi", "poly"].includes(
        this.chain
      );
    },
    mapIdIsValid() {
      const regex = new RegExp("^[A-Za-z0-9]{20}$");
      return regex.test(this.mapId);
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open();
      this.$refs.input.focus();
    },
    close() {
      this.$refs.dialog.close();
    },
    async handleConfirm() {
      try {
        const address = this.address.toLowerCase();
        const mapId = this.mapId != "" ? this.mapId : null;
        const data = {
          chain: this.chain,
          address: address,
          requested_id: mapId,
        };
        const authorId = this.loginStore.user.uid;
        await this.labelsStore.queueScrap(authorId, data);
        alert("Success!");
        this.chain = "";
        this.address = "";
        this.id = "";
        this.close();
      } catch (error) {
        alert(
          "Failed to commit to DB, check your internet connection and try again."
        );
        throw error;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.queue-scrap-form {
  text-align: center;
}

.queue-scrap-form__field {
  width: 100%;
}

.queue-scrap-form__warning {
  font-size: 0.8em;
  font-weight: 500;
  color: orange;
}

.queue-scrap-form__button {
  margin: 10px 4px 0 4px;
}
</style>
