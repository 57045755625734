import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBc-Gns-Nf0c47NIk02WVVe1xc66HQukXU",
  authDomain: "cryptos-tools.firebaseapp.com",
  projectId: "cryptos-tools",
  storageBucket: "cryptos-tools.appspot.com",
  messagingSenderId: "752909939035",
  appId: "1:752909939035:web:dd3efbfa64255bb2622a7e",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const bucketUrl = "gs://public-bubblemaps";
export const storage = getStorage(app, bucketUrl);
export default app;
