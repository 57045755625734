<template>
  <div class="date-picker-button">
    <input ref="input" :value="modelValue" />
    <i class="material-icons" aria-hidden="true"> edit </i>
  </div>
</template>

<script>
import duDatepicker from "@dmuy/datepicker";

export default {
  name: "DatePicker",
  props: {
    modelValue: String,
  },
  mounted: function () {
    duDatepicker(this.$refs.input, {
      clearBtn: true,
      theme: "purple",
      format: "yyyy-mm-dd",
    });
    let that = this;
    this.$refs.input.addEventListener("datechanged", function () {
      that.$emit("update:modelValue", this.value);
    });
  },
  methods: {
    initDate(date) {
      if (date) {
        duDatepicker(this.$refs.input, "setValue", date);
      } else {
        duDatepicker(
          this.$refs.input,
          "setValue",
          this.$format.dayDate(new Date())
        );
        this.$emit("update:modelValue", null);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@dmuy/datepicker/dist/duDatepicker.css";
@import "assets/variables";

.date-picker-button {
  width: 30px;
  height: 30px;
  position: relative;

  input {
    width: 30px;
    height: 30px;
    border: none;
    background-color: $theme-purple;
    color: $theme-purple;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
  }

  i {
    position: absolute;
    left: 4px;
    top: 4px;
    color: white;
    pointer-events: none;
  }
}
</style>
