<script setup>
import { useTokensStore } from "@/stores/tokens.js";
import { nextTick, watch, ref } from "vue";
import CenteredDialog from "./CenteredDialog.vue";
import ImageUploader from "./ImageUploader.vue";

const tokens = useTokensStore();

const addATokenDialog = ref(null);

watch(
  () => tokens.addingAToken,
  (addingAToken) => {
    if (addingAToken) {
      nextTick(() => {
        addATokenDialog.value.focus();
        document.querySelector("body").style.overflowY = "clip";
      });
    }
    if (!addingAToken) {
      nextTick(() => {
        document.querySelector("body").style.overflowY = "auto";
      });
    }
  }
);
</script>
<template>
  <div v-show="tokens.addingAToken">
    <CenteredDialog>
      <div
        ref="addATokenDialog"
        tabindex="-1"
        @keyup.esc="tokens.addingAToken = false"
        id="addATokenDialog"
      >
        <form @submit.prevent="">
          <div>
            <h2>Add a Token</h2>
            <div v-if="tokens.blockchain && tokens.blockchain.tokens">
              <label for="place_after_full_name">place_after_full_name*</label>
              <input
                autocomplete="do-not-autofill"
                required
                :pattern="
                  tokens.place_after_full_name_valid
                    ? tokens.newToken.place_after_full_name
                    : 'invalidfullname'
                "
                v-model="tokens.newToken.place_after_full_name"
                type="text"
                name="place_after_full_name"
                id="place_after_full_name"
                list="tokenSuggestions"
              />
              <datalist id="tokenSuggestions">
                <option
                  :value="tokenSuggestion.full_name"
                  v-for="(tokenSuggestion, tokenSuggestionI) in tokens
                    .newBlockchain.tokens"
                  :key="tokenSuggestionI"
                ></option>
              </datalist>
            </div>
            <div>
              position:
              {{ tokens.place_after_position }}
            </div>
            <div>
              <label for="address">address*</label>
              <!--
                Example of valid input
                0x1234567890123456789012345678901234567890
               -->
              <input
                autocomplete="do-not-autofill"
                required
                title="40 hexadecimal characters that begins with '0x' or 32-44 alphanumeric characters"
                pattern="^(0x[a-fA-F0-9]{40})|([1-9A-HJ-NP-Za-km-z]{32,44})$"
                v-model="tokens.newToken.address"
                type="text"
                name="address"
                id="address"
              />
            </div>
            <div>
              <label for="full_name">full_name*</label>
              <input
                autocomplete="do-not-autofill"
                required
                :pattern="
                  tokens.full_name_valid
                    ? tokens.newToken.full_name
                    : 'invalidfullname'
                "
                v-model="tokens.newToken.full_name"
                type="text"
                name="full_name"
                id="full_name"
              />
              <div v-if="!tokens.full_name_valid" class="error-message">
                Already taken
              </div>
            </div>
            <div class="checkboxInputWrapper">
              <label for="is_soon">is_soon</label>
              <input
                v-model="tokens.newToken.is_soon"
                type="checkbox"
                name="is_soon"
                id="is_soon"
              />
            </div>
            <div class="checkboxInputWrapper">
              <label for="is_moonscan">is_moonscan</label>
              <input
                v-model="tokens.newToken.is_moonscan"
                type="checkbox"
                name="is_moonscan"
                id="is_moonscan"
              />
            </div>
            <div class="checkboxInputWrapper">
              <label for="is_rugged">is_rugged</label>
              <input
                v-model="tokens.newToken.is_rugged"
                type="checkbox"
                name="is_rugged"
                id="is_rugged"
              />
            </div>
            <div v-if="tokens.addingAToken">
              <ImageUploader />
            </div>
            <div>
              <label for="website">website*</label>
              <input
                required
                pattern="^https:\/\/\S+\.\S+$"
                v-model="tokens.newToken.website"
                type="url"
                name="website"
                id="website"
              />
            </div>
            <div>
              <label for="website">youtube</label>
              <input
                pattern="^https:\/\/\S+\.\S+$"
                v-model="tokens.newToken.youtube"
                type="url"
                name="website"
                id="website"
              />
            </div>
            <div class="dialog-actions-wrapper">
              <div>
                <button class="elevated" @click="tokens.addingAToken = false">
                  Cancel
                </button>
              </div>
              <div>
                <button
                  class="elevated primary"
                  :disabled="
                    !tokens.previewUrl ||
                    !tokens.newToken.full_name ||
                    !tokens.newToken.address ||
                    !tokens.newToken.website ||
                    !tokens.newToken.place_after_full_name ||
                    !tokens.place_after_full_name_valid ||
                    !tokens.full_name_valid ||
                    !tokens.newToken.website.match(/^https:\/\/\S+\.\S+$/) ||
                    (tokens.newToken.youtube.length > 0 &&
                      !tokens.newToken.youtube.match(/^https:\/\/\S+\.\S+$/))
                  "
                  type="submit"
                  value="Submit"
                  @click="tokens.addNewTokenToChanges()"
                >
                  <div>Add</div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CenteredDialog>
  </div>
</template>
